import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Table } from 'react-bootstrap';
import styles from './index.module.css';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import parser from 'html-react-parser';

const StyleWrapper = styled.div`

a{
    text-decoration: none;
}
.tablebg {
    background: #ececec;
}

.Disclaimer_disclaimerTable__60Hyotable.table-bordered.table td {
    padding: 15px;
}
`

const Disclaimer = () => {
    const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;
    const [termsAndConditionsData, setTermsAndConditionsData] = useState();
    const [stateLicenses, setStateLicenses] = useState([]);

    useEffect(() => {
        const fetchTermsAndConditions = async () => {
            try {
                await axios
                    .post(process.env.REACT_APP_TERMS_AND_CONDITIONS, { websiteName: 'swmc.com' })
                    .then((response) => {
                        // console.log(JSON.stringify(response.data.data));
                        setTermsAndConditionsData(response.data.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (e) {
                console.log(e);
            }
        };

        fetchTermsAndConditions();
    }, [termsAndConditionsData]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const fetchStateLicenses = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_DISCLAIMER_JSON);
                setStateLicenses(response.data);
            } catch (error) {
                console.error('Error fetching state licenses:', error);
            }
        };

        fetchStateLicenses();
    }, []); // Dependency array added here

    return (
        <article>
            <Helmet>
                <title>Disclaimers | LowRates</title>
                <meta name="description" content="LowRates, Mortgage Loans" />
                <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />


            </Helmet>
            <StyleWrapper>
                <img
                    src={imgCDNURL + "loanprogrambannerimage.webp"}
                    className={styles.loanprogrambannerimage}

                />

                <img
                    src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
                    className={styles.loanprogrambannerimagemobile}

                />
                <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
                    <img
                        src={imgCDNURL + "applynowbanner.webp"}
                        className={styles.applynowbanner}
                    />
                </a>
                <div className={styles.reficalculatorStyleDiv}>
                    <Container className={styles.disclaimerContainer}>
                        <div className={styles.title}>
                            <h1>Disclaimers</h1>
                            {termsAndConditionsData ?
                                <div className='mb-3' style={{ textAlign: 'justify' }}>
                                    {
                                        termsAndConditionsData.angelaidisclaimer.map((angelaidisclaimer, i) => (
                                            <p key={i}>
                                                {parser(angelaidisclaimer)}
                                            </p>
                                        ))
                                    }
                                </div>
                                : ""}
                            <h2>LowRates by Sun West Mortgage Company, Inc. (NMLS ID 3277)</h2>
                            <h5>State Licenses</h5>
                        </div>

                        <p >Licenses can be verified at: <a href="http://www.nmlsconsumeraccess.org/" title="NMLS Consumer Access"
                            target="_blank"
                            rel="noreferrer">www.nmlsconsumeraccess.org</a></p>
                        <p >In all jurisdictions, the main licensed location of Sun West Mortgage Company, Inc. is <a className={styles.info_block_anchor} href="https://goo.gl/maps/vmWafJ6pieBNEJ357" target="_blank">18303 Gridley Rd., Cerritos, CA 90703</a>, Phone: <a href="tel:+18004537884">(800) 453-7884</a>.</p>
                        
                        <Table responsive className={`${styles.disclaimerTable} table table-bordered`}>
                            <thead className={styles.tableHead}>
                                <tr>
                                    <th>State</th>
                                    <th>License Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stateLicenses.map((license, index) => (
                                    <tr key={index}>
                                        <td>{license.state}</td>
                                        <td>{parser(license.licenseDescription)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        
                    </Container>
                    
                </div>
            </StyleWrapper>
        </article>
    );
}

export default Disclaimer;