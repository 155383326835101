import { Container, Col, Row } from 'react-bootstrap';

export const NoticeSection = ({ styles }) => (
    <Container className={styles.share_section} style={{
        padding: '0'
    }}>
        <Col className={styles.share_section_col}>
            <Row className={
                `${styles.share_section_row} ${styles.blue_row} ${styles.share_section_strong}`
            }>
                <Col className={styles.share_section_row_desc}>
                    <h2 className='text-white'>
                        Who we are
                    </h2>
                </Col>
            </Row>
            <Row className={`${styles.share_section_row} ${styles.light_gray}`}>
                <Col lg={5} md={4} sm={4} xs={5} className={`${styles.share_section_row_desc} ${styles.share_section_strong}`}>
                    <span>
                        Who is providing this notice?
                    </span>
                </Col>
                <Col className={styles.share_section_row_flag2}>
                    <span>
                        LowRates.com by Sun West Mortgage Company, Inc. (NMLS ID 3277) is your mortgage servicer.
                    </span>
                </Col>
            </Row>
        </Col>
    </Container>
)