import React, { useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import styles from './index.module.css'
import { FactsSection } from "./FactsSection";
import { ShareSection } from "./ShareSection";
import { ContactSection } from "./ContactSection";
import { NoticeSection } from "./NoticeSection";
import { ProcessRelated } from "./ProcessRelated";
import { Definitions } from "./Definitions";
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <article>
            <Helmet>
            <title>Privacy Policy | LowRates</title>
            <meta name="description" content="LowRates, Mortgage Loans"/>
            <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible"/>
            </Helmet>
           

     
        <Container className={styles.privacy_container}>
            <div className={styles.title}>
                <h1>PRIVACY POLICY</h1>
            </div>

            <FactsSection styles={styles} />

            <ShareSection styles={styles} />

            <ContactSection styles={styles} />

            <Container className={styles.facts_section}>
                <Col className={styles.facts_section_col}>
                    <Row className={`${styles.facts_section_row} ${styles.border_bottom_none}`}>
                        <Col md={4} sm={4} xs={4} className={styles.facts_section_row_heading}>
                            <div className={`${styles.strong} ${styles.share_section_row_desc}`}>Questions?</div>
                        </Col>
                        <Col md={8} sm={8} xs={8} className={styles.facts_section_row_desc}>
                            <div>
                                Call our customer service department at toll-free number, <a style={{textDecoration:'none'}} href="tel:+18004537884">800.453.7884</a>, between the hours of 7.00 AM
                                and 7.00 PM PT, Monday through Friday, except Federal Holidays.
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Container>

            <NoticeSection styles={styles} />

            <ProcessRelated styles={styles} />

            <Definitions styles={styles} />

        </Container>
        </article>
    );
}

export default PrivacyPolicy;