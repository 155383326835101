import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Classes from './index.module.css';
import axios from 'axios'; // Import axios
import sitemapData from './sitemapData.json'; // Import the JSON data locally

const SiteMap = (props) => {
  const [sitemapData, setSitemapData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (process.env.REACT_APP_LOWRATES_SITEMAP_JSON) {
          response = await axios.get(process.env.REACT_APP_LOWRATES_SITEMAP_JSON);
        } else {
          // Set the local JSON data if environment variable is not defined
          setSitemapData(sitemapData);
          return; // Exit early since data is already set
        }
        if (response.status !== 200) {
          throw new Error('Failed to fetch sitemap data');
        }
        const data = response.data;
        setSitemapData(data);
      } catch (error) {
        console.error('Error fetching sitemap data:', error);
      }
    };

    fetchData();
  }, []);

  if (!sitemapData) {
    // Render loading indicator or placeholder while data is being fetched
    return <div>Loading...</div>;
  }

  return (
    <Container fluid className={Classes.pageContainer}>
      <Row>
        {sitemapData.sections.map((section, index) => (
          <Col key={index} sm={{ span: 4, offset: index > 0 ? 0 : 0 }}>
            <div className={Classes.site_map_section}>
              <ul>
                <li className={Classes.content_head}>
                  {/* Check if there's a link for the title, if not, render it as plain text */}
                  {section.link ? (
                    <Link to={section.link}>{section.title}</Link>
                  ) : (
                    <span>{section.title}</span>
                  )}
                </li>
                {section.links && section.links.map((link, idx) => (
                  <li key={idx}>
                    {link.external ? (
                      <a href={link.link} target="_blank" rel="noopener noreferrer">{link.title}</a>
                    ) : (
                      <Link to={link.link}>{link.title}</Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SiteMap;
