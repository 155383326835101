import { Container, Col, Row } from 'react-bootstrap';

const CDNURL = process.env.REACT_APP_LOWRATES_RESOURCES || 'https://defaulturl.com/';

export const ContactSection = ({ styles }) => (
    <Container className={`${styles.contact_section} ${styles.padded}`}>
        <Row className={styles.contact_section_row}>
            <Col lg={4} md={4} sm={4} xs={4} className={`${styles.vertical_middle} ${styles.contact_section_left_col}`}>
                <div className={styles.padded}>
                    To start our sharing with non-affiliates or to stop our sharing with affiliates for marketing purposes
                </div>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
                <div>
                    <ul>
                        <li>Call <a href='tel:+18004537884'>(800) 453-7884</a><b> or</b></li>
                        <li>Mail the <b>form</b> below 
                        <br/>
                        (<a 
                            href={`${CDNURL}Limit_our_sharing_form.pdf`}
                            target="_blank" rel="noopener noreferrer">
                            Click here
                        </a> to download the form).
                        </li>
                    </ul>
                    <h2>Please note:</h2>
                    <p>
                        If you are a new customer, we can begin sharing your information 30 days from the date we sent this notice.&nbsp;
                        When you are no longer our customer, we can continue to share your information for up to 18 months after you&nbsp;
                        are no longer our customer.
                    </p>
                    <p>However, you can contact us at any time to limit our sharing.</p>
                </div>
            </Col>
        </Row>
    </Container>
);
