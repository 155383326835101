import React, { useEffect, useState } from 'react';
import Classes from '../../containers/HomeBuyers/index.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { NavLink, Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BlueButton from '../../components/BlueButton';
import ResourcesMenu from '../../components/ResourcesMenu';
import HowItWorks from '../../components/HowItWorks';
import WhiteButton from '../../components/WhiteButton';
import RequiredDocs from '../RequiredDocs';
import Calculators from '../Calculators';
import { Helmet } from 'react-helmet';


const NavWrapper = styled.div`
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    color: #00AAE4;
    background-color: transparent;
    // border-bottom:1px solid #3074b7;
    padding: 0.8rem 1rem;
    border-radius: 0!important;

}
.nav-link {
    display: block;
    font-size: 20.1px;
    font-weight: 800;
    padding: 0.8rem 1rem;
    color: #083F88;
    border-radius: 0!important;
}

@media(min-width: 1200px)and (max-width:1400px) {
    .nav-link {
        font-size: 18.1px;
    }
}

@media(min-width: 992px)and (max-width:1199.7px) {
    .nav-link {
        font-size: 15.1px;
        font-weight: 900;
    }
}

@media(min-width: 768px)and (max-width:991.7px) {
    .nav-link {
        font-size: 12.7px;
        padding: 0.5rem 0.5rem;
        font-weight: 900;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}

@media(max-width:767px){
    .nav-link {
        display: block;
        padding: 1rem 1rem;
        width:max-content;
        color:black;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        color: #3074b7;
        background-color: transparent;
        padding: 1rem 1rem;
        border-bottom:1px solid #3074b7;
        border-radius:0px;
    }
}
`

const routeKeys = {
    homebuyersguide: "homebuyersguide",
    refinancingguide: "refinancingguide",
    documentsneeded: "documentsneeded",
    mortgagecalculator: "mortgagecalculator"
}

const Resources = ({ history }) => {
    const [key, setKey] = useState('documentsneeded');
    const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;
    let { eventKey } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
        //   history.push('/conventional-loans')
    }, [])

    useEffect(() => {

        if (eventKey && routeKeys[eventKey]) {
            setTimeout(() => {
                setKey(eventKey)
                window.scrollTo(0, 0)
            }, 200)

        }
    }, [eventKey])

    return (
        <article style={{ backgroundColor: '#FCFCFC' }}>

            <Helmet>
                <title>Documents | LowRates</title>
                <meta name="description" content="LowRates, Mortgage Loans" />
                <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />

            </Helmet>

            <div className={Classes.loanProgramDiv}>

                <img
                    src={imgCDNURL + "loanprogrambannerimage.webp"}
                    className={Classes.loanprogrambannerimage}

                />

                <img
                    src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
                    className={Classes.loanprogrambannerimagemobile}

                />
                <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
                    <img
                        src={imgCDNURL + "applynowbanner.webp"}
                        className={Classes.applynowbanner}
                    />
                </a>

                {key !== routeKeys.documentsneeded && key !== routeKeys.mortgagecalculator && (
                    <Container className={Classes.loanProgramContainer}>

                        <NavWrapper>
                            <Tab.Container id="left-tabs-example" activeKey={key}
                                onSelect={(k) => setKey(k)}>
                                <Row>
                                    <Col md={12} lg={12} sm={12} className={Classes.fixedLeft}>
                                        <h4 className={Classes.leftHeader}>Loan Services</h4>
                                        <Row>
                                            <Col md={6} lg={6} sm={12} >
                                                <Nav variant="pills" className={Classes.setNavLeft}>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.conventional} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} to="/conventional-loans">CONVENTIONAL LOANS</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.fha} as={NavLink} to="/fha-loans" activeClassName={Classes.activePillLink} className={Classes.pillLink}>FHA LOANS</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.investment} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} to="/investment-loans">INVESTMENT PROPERTIES</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.jumbo} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink1} to="/jumbo-loans">JUMBO LOANS</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                            <Col md={6} lg={6} sm={12} >
                                                <Nav variant="pills" className={Classes.setNavLeft}>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.renovation} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} to="/renovation-loans">RENOVATION AND CONSTRUCTION LOANS</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.refinancing} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} to="/refinance">REFINANCING</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.reverse} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} to="/reverse-mortgage">REVERSE MORTGAGE</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.va} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink1} to="/va-loans">VA LOANS</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </NavWrapper>
                    </Container>
                )}

                <div className={key !== routeKeys.documentsneeded && key !== routeKeys.mortgagecalculator ? Classes.loanProgramContentDiv : Classes.documentsNeededStyleDiv}>
                    <Container className={Classes.loanProgramContainer}>
                        <NavWrapper>
                            <Tab.Container id="left-tabs-example" activeKey={key}
                                onSelect={(k) => setKey(k)}>
                                <Row>

                                    <Col sm={12} lg={12} md={12} className={Classes.rightCol}>
                                        <h1 className={Classes.loanHeader}>
                                            {key === routeKeys.homebuyersguide && 'HOME BUYER\'S GUIDE'}
                                            {key === routeKeys.refinancingguide && 'REFINANCING GUIDE'}
                                        </h1>

                                        <div className={Classes.conventional}>
                                                <Tab.Pane eventKey={routeKeys.documentsneeded}>
                                                    <div className={Classes.requiredDocsDiv}>
                                                        <RequiredDocs />
                                                    </div>
                                                </Tab.Pane>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </NavWrapper>

                    </Container>
                </div>
            </div>

            {key !== routeKeys.documentsneeded && key !== routeKeys.mortgagecalculator && (
                <div className={Classes.loanhowitworksDiv}>

                    <Container className={Classes.loanhowitworksContainer}>
                        <HowItWorks />
                    </Container>
                </div>
            )}

            <div className={Classes.loanresourcesDiv}>

                <Container className={Classes.loanresourcesContainer}>
                    <ResourcesMenu />
                </Container>
            </div>
        </article>
    )
}

export default Resources