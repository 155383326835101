import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import { BiStar, BiPaperPlane, BiBookmark } from "react-icons/bi";
import { FaRegLightbulb } from "react-icons/fa";
import "./index.css";
import { Link } from "react-router-dom";

export default function ResourcesMenu(props) {
  const [aosProps, setAosProps] = useState({
    title: {
      "data-aos": "fade-up",
      "data-aos-duration": "1000",
    },
    card: {
      "data-aos": "zoom-in",
      "data-aos-duration": "1000",
    },
  });

  useEffect(() => {
    if (props.dontAnimate) {
      setAosProps((currentObj) => ({
        ...currentObj,
        title: {},
        card: {},
      }));
    }
  }, [props.dontAnimate]);

  

  const handleClick = () => {
    window.scrollTo(0, 0); // Scroll to top before reloading
  };
 

  return (
    <div id="resources-wrapper">
      {/* <Image src={`${process.env.REACT_APP_LOWRATES_RESOURCES}images/blueDots.png`} alt="Left blue dots" fluid className="leftTopBlueDots" /> */}
      <Container id="resourcesContainer" className="servicesCardContainer">
        <Row className="">
          <Col md={3} >
            <Link to='/home-buying' rel="noreferrer" className="servicesCardLink" onClick={() => handleClick('/home-buying')}>
              <Card>
                <figure>
                  <Card.Img
                    variant="top"
                    className="cardImg"
                    src={`${process.env.REACT_APP_LOWRATES_RESOURCES}images/img-pricing-01.jpg`}
                    alt="Buying A Home"
                    loading="lazy"
                    width={""}
                    height={""}
                  />
                  <span className="badge badge-theme">
                    <BiStar className="badgeIcons" size="2.5em" />
                  </span>
                </figure>
                <Card.Body className="text-center">
                  <Card.Title className="contentcardinfo">Buying A Home</Card.Title>
                </Card.Body>
              </Card>
            </Link>
          </Col>

          <Col md={3} >
            <Link to='https://www.swmc.com/angelai/calculators' target="_blank" rel="noreferrer" className="servicesCardLink" 
            // onClick={() => handleClick('/mortgage-calculators')}
            >
              <Card>
                <figure>
                  <Card.Img
                    variant="top"
                    className="cardImg"
                    src={`${process.env.REACT_APP_LOWRATES_RESOURCES}images/img-pricing-02.jpg`}
                    alt="Mortgage Calculators"
                    loading="lazy"
                    width={""}
                    height={""}
                  />
                  <span className="badge badge-theme">
                    <BiBookmark className="badgeIcons" size="2.5em" />
                  </span>
                </figure>
                <Card.Body className="text-center">
                  <Card.Title className="contentcardinfo">Mortgage Calculators</Card.Title>
                </Card.Body>
              </Card>
            </Link>
          </Col>

          <Col md={3} >
            <Link to='/documents' rel="noreferrer" className="servicesCardLink" onClick={() => handleClick('/documents')}>
              <Card>
                <figure>
                  <Card.Img
                    variant="top"
                    className="cardImg"
                    src={`${process.env.REACT_APP_LOWRATES_RESOURCES}images/img-pricing-03.jpg`}
                    alt="Documents Needed"
                    loading="lazy"
                    width={""}
                    height={""}
                  />
                  <span className="badge badge-theme">
                    <BiPaperPlane className="badgeIcons" size="2.5em" />
                  </span>
                </figure>
                <Card.Body className="text-center">
                  <Card.Title className="contentcardinfo">Documents Needed</Card.Title>
                </Card.Body>
              </Card>
            </Link>
          </Col>

          <Col md={3} >
            <Link to='/refinancing-guide' rel="noreferrer" className="servicesCardLink" onClick={() => handleClick('/refinancing-guide')}>
              <Card className="mb-0">
                <figure>
                  <Card.Img
                    variant="top"
                    className="cardImg"
                    src={`${process.env.REACT_APP_LOWRATES_RESOURCES}images/img-pricing-04.jpg`}
                    alt="Refinancing Guide"
                    loading="lazy"
                    width={""}
                    height={""}
                  />
                  <span className="badge badge-theme">
                    <FaRegLightbulb className="badgeIcons" size="2em" />
                  </span>
                </figure>
                <Card.Body className="text-center">
                  <Card.Title className="contentcardinfo">Refinancing Guide</Card.Title>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
