import React, { useEffect, useRef, useState } from 'react';
import Classes from '../../containers/HomeBuyers/index.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { NavLink, Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BlueButton from '../../components/BlueButton';
import ResourcesMenu from '../../components/ResourcesMenu';
import HowItWorks from '../../components/HowItWorks';
import WhiteButton from '../../components/WhiteButton';
import RequiredDocs from '../RequiredDocs';
import Calculators from '../Calculators';
import { Helmet } from 'react-helmet';


const NavWrapper = styled.div`
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    color: #00AAE4;
    background-color: transparent;
    // border-bottom:1px solid #3074b7;
    padding: 0.8rem 1rem;
    border-radius: 0!important;

}
.nav-link {
    display: block;
    font-size: 20.1px;
    font-weight: 800;
    padding: 0.8rem 1rem;
    color: #083F88;
    border-radius: 0!important;
}

@media(min-width: 1200px)and (max-width:1400px) {
    .nav-link {
        font-size: 18.1px;
    }
}

@media(min-width: 992px)and (max-width:1199.7px) {
    .nav-link {
        font-size: 15.1px;
        font-weight: 900;
    }
}

@media(min-width: 768px)and (max-width:991.7px) {
    .nav-link {
        font-size: 12.7px;
        padding: 0.5rem 0.5rem;
        font-weight: 900;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}

@media(max-width:767px){
    .nav-link {
        font-size: 15.5px;
        padding: 0.5rem 0.5rem;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}
}
`

const routeKeys = {
    homebuyersguide: "homebuyersguide",
    refinancingguide: "refinancingguide",
    documentsneeded: "documentsneeded",
    mortgagecalculator: "mortgagecalculator"
}

const Resources = ({ history }) => {
    const [key, setKey] = useState('homebuyersguide');
    const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;
    const [navItemClicked, setNavItemClicked] = useState(false);
    let { eventKey } = useParams();
    const mainContentRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    const handleNavItemClick = (selectedKey) => {
        setKey(selectedKey);
        setNavItemClicked(true);
        if (mainContentRef.current) {
            window.scrollTo({
                top: mainContentRef.current.offsetTop,
                behavior: "smooth"
            });
        }
    };

    return (
        <article style={{ backgroundColor: '#FCFCFC' }}>

            <Helmet>
                <title>Home Buyer's Guide | LowRates</title>
                <meta name="description" content="LowRates, Mortgage Loans" />
                <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />

            </Helmet>

            <div className={Classes.loanProgramDiv}>

                <img
                    src={imgCDNURL + "loanprogrambannerimage.webp"}
                    className={Classes.loanprogrambannerimage}

                />

                <img
                    src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
                    className={Classes.loanprogrambannerimagemobile}

                />
                <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
                    <img
                        src={imgCDNURL + "applynowbanner.webp"}
                        className={Classes.applynowbanner}
                    />
                </a>

                {key !== routeKeys.documentsneeded && key !== routeKeys.mortgagecalculator && (
                    <Container className={Classes.loanProgramContainer}>

                        <NavWrapper>
                            <Tab.Container id="left-tabs-example" activeKey={key}
                                onSelect={handleNavItemClick}>
                                <Row>
                                    <Col md={12} lg={12} sm={12} className={Classes.fixedLeft}>
                                        <h4 className={Classes.leftHeader}>Loan Services</h4>
                                        <Row>
                                            <Col md={6} lg={6} sm={12} >
                                                <Nav variant="pills" className={Classes.setNavLeft}>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.conventional} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/conventional-loans", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.conventional);
                                                            }
                                                        }} to="/conventional-loans">CONVENTIONAL LOANS</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.fha} as={NavLink} to="/fha-loans" activeClassName={Classes.activePillLink} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/fha-loans", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.fha);
                                                            }
                                                        }} className={Classes.pillLink}>FHA LOANS</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.investment} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/investment-loans", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.investment);
                                                            }
                                                        }} to="/investment-loans">INVESTMENT PROPERTIES</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.jumbo} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink1} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/jumbo-loan", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.jumbo);
                                                            }
                                                        }} to="/jumbo-loans">JUMBO LOANS</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                            <Col md={6} lg={6} sm={12} >
                                                <Nav variant="pills" className={Classes.setNavLeft}>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.renovation} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/renovation-loans", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.renovation);
                                                            }
                                                        }} to="/renovation-loans">RENOVATION AND CONSTRUCTION LOANS</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.refinancing} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/refinance", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.refinancing);
                                                            }
                                                        }} to="/refinance">REFINANCING</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.reverse} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/reverse-mortgage", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.reverse);
                                                            }
                                                        }} to="/reverse-mortgage">REVERSE MORTGAGE</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.va} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink2} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/va-loans", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.va);
                                                            }
                                                        }} to="/va-loans">VA LOANS</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </NavWrapper>
                    </Container>
                )}

                <div ref={mainContentRef} className={key !== routeKeys.documentsneeded && key !== routeKeys.mortgagecalculator ? Classes.loanProgramContentDiv : Classes.documentsNeededStyleDiv}>
                    <Container className={Classes.loanProgramContainer}>
                        <NavWrapper>
                            <Tab.Container id="left-tabs-example" activeKey={key}
                                onSelect={(k) => setKey(k)}>
                                <Row>

                                    <Col sm={12} lg={12} md={12} className={Classes.rightCol}>
                                        <h1 className={Classes.loanHeader}>
                                            {key === routeKeys.homebuyersguide && 'HOME BUYER\'S GUIDE'}
                                            {key === routeKeys.refinancingguide && 'REFINANCING GUIDE'}
                                        </h1>

                                        <div className={Classes.conventional}>
                                            <Tab.Pane eventKey={routeKeys.homebuyersguide}>
                                                <img
                                                    src={imgCDNURL + "homebuyerimage.webp"}
                                                    className={Classes.loanpurposeimage}
                                                    alt="homebuying guide image"
                                                />
                                                <h3>Get Ready to Buy a Home</h3>
                                                <p>
                                                    How do you know if you and your family are ready to commit to a new home? If the thought of&nbsp;
                                                    homeownership has been on your mind for a while, it might be a sign it’s time to make the leap&nbsp;
                                                    into purchasing a new property.
                                                </p>
                                                <p>While you may be emotionally ready, how can you be sure you’re financially ready? Ask yourself these financial questions before heading into the home buying process to make sure you and your bank account are both ready:</p>
                                                <ul>
                                                    <li>Is my income stable?</li>
                                                    <li>Am I able to put a small percentage of my income away each month into savings?</li>
                                                    <li>Is my monthly college and/or car debt manageable?</li>
                                                    <li>Do I have a good credit score?</li>
                                                    <li>Is my savings account already in place, and do I have enough set aside to pay a small down payment?</li>
                                                </ul>
                                                <p>If you answered ‘yes’ to most or all of these questions, your financial situation is likely acceptable for home buying requirements.</p>

                                                <h3>View Your Home Loan Options</h3>
                                                <p>At LowRates, we offer the following home loans:</p>
                                                <ul>
                                                    <li>
                                                        <Link to="/fha-loans">FHA </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/conventional-loans">Conventional</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/jumbo-loans">Jumbo</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/va-loans">VA</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/refinance">Refinancing</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/investment-loans">Investment Properties</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/reverse-mortgage">Reverse Mortgage</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/renovation-loans">Renovation And Construction Loans</Link>
                                                    </li>
                                                </ul>
                                                <p>If you’re interested in learning more about the home loans listed above, contact a member of our team via phone or email. They will help explain more about each type of loan and which might be best for you, considering your financial situation and home buying goals.</p>
                                                <h3>Get Approved for a Mortgage</h3>
                                                <p>After our team explains more about the mortgage process to you, you will be able to make an appointment with our team if you’re interested in moving forward with an application.</p>
                                                <p>After filling out and sending in your application, our team will review your documents to ensure that the chosen home loan is a good fit for your family. We will look into your household income, current assets, outstanding debt, and overall credit score.</p>
                                                <h3>Work With a Real Estate Agent</h3>
                                                <p>Once you’re approved, congratulations! We recommend you then work with a real estate agent who can help you find the perfect house in a good neighborhood and solidify a good price on the home.</p>
                                                <p>We have a list of preferred and recommended agents. We will be happy to pass along their information after your mortgage application is approved.</p>
                                                <h3>Find Your Perfect Home</h3>
                                                <p>With your real estate agent’s tips and online search tools from Zillow and Trulia, you can search for the best options in your area. Some things to consider when starting your home search:</p>
                                                <ul>
                                                    <li>The neighborhood</li>
                                                    <li>Local schools</li>
                                                    <li>The commute time from your work to the home</li>
                                                    <li>The nearby amenities, such as grocery stores and gas stations</li>
                                                    <li>The price</li>
                                                    <li>The number of rooms/bathrooms needed for your family</li>
                                                </ul>
                                                <p>Many search tools will allow you to look for homes based on some of the criteria above, like schools, price, and home size. If you’re still struggling to find the right home for you, talk to your agent or your lender for help.</p>
                                                <h3>Make an Offer on the House</h3>
                                                <p>Once you’ve found your dream home, what’s next? More often than not, the next step is to make an offer on the home. Negotiating prices comes easier to some than others, so your real estate agent will guide you step-by-step through the process of making an offer.</p>
                                                <p>A good way to get an idea of how much to offer is to look at the nearby homes and what they were sold for. By finding the price of homes that are a comparable size and quality, you can make an educated assumption on a good price to offer for the home.</p>
                                                <p>Before making the offer, however, make sure there are no or are little repairs and renovations you will want to make to the house. If so, you will need to account for these adjustments in your budget and the price you can offer may differ with this in mind.</p>
                                                <h3>Get Ready to Close</h3>
                                                <p>Once your offer has been accepted, there are 3 things that will need to happen before you can close on the home. They are:</p>
                                                <ol>
                                                    <li style={{ fontWeight: 900 }}>A home inspection</li>
                                                    <p>The first step requires you to find and hire an inspector to double check that the home is in good condition. This may seem like a tedious step, but it is essential to make sure you are not being ripped off by the current home owners, or will be faced with an unexpected issue soon after purchasing.</p>
                                                    <li style={{ fontWeight: 900 }}>An appraisal</li>
                                                    <p>Once you’ve received the home inspection, our team will order an appraisal for you. This step is important to make sure you don’t pay more for the home than it’s worth. A typical appraisal costs a few hundred dollars, so keep this in mind when budgeting for closing costs.</p>
                                                    <li style={{ fontWeight: 900 }}>The underwriting</li>
                                                    <p>While the previous two steps are happening, our team will work on underwriting your home loan, which means we will verify your financial status in order to give the loan a final approval.</p>
                                                </ol>
                                                <h3>Close on Your New Home</h3>
                                                <p>For most, this is the best step of the process. After going through the lengthy process of finding a home and applying for loan, you will finally get to sign and officiate your ownership. Before making it official, however, your lender will walk you through the Closing Disclosure document and double check that all details of your loan look correct. You will also need to do a final walk-through of the house to ensure that everything looks good before closing.</p>
                                                <p>In the instance that you miss a mortgage payment, you can always contact our team and we will work with you to create a new payment plan, if necessary. If this happens, contact your lender right away to discuss options.</p>
                                            </Tab.Pane>

                                        </div>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </NavWrapper>

                    </Container>
                </div>
            </div>

            {key !== routeKeys.documentsneeded && key !== routeKeys.mortgagecalculator && (
                <div className={Classes.loanhowitworksDiv}>

                    <Container className={Classes.loanhowitworksContainer}>
                        <HowItWorks />
                    </Container>
                </div>
            )}

            <div className={Classes.loanresourcesDiv}>

                <Container className={Classes.loanresourcesContainer}>
                    <ResourcesMenu />
                </Container>
            </div>
        </article>
    )
}

export default Resources