import React, { useEffect, useRef, useState } from 'react';
import Classes from '../../containers/LoanPrograms/index.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { NavLink, Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BlueButton from '../../components/BlueButton';
import ResourcesMenu from '../../components/ResourcesMenu';
import HowItWorks from '../../components/HowItWorks';
import WhiteButton from '../../components/WhiteButton';
import { Helmet } from 'react-helmet';


const NavWrapper = styled.div`
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    color: #00AAE4;
    background-color: transparent;
    // border-bottom:1px solid #3074b7;
    padding: 0.8rem 1rem;
    border-radius: 0!important;

}
.nav-link {
    display: block;
    font-size: 20.1px;
    font-weight: 800;
    padding: 0.8rem 1rem;
    color: #083F88;
    border-radius: 0!important;
}

@media(min-width: 1200px)and (max-width:1400px) {
    .nav-link {
        font-size: 18.1px;
    }
}

@media(min-width: 992px)and (max-width:1199.7px) {
    .nav-link {
        font-size: 15.1px;
        font-weight: 900;
    }
}

@media(min-width: 768px)and (max-width:991.7px) {
    .nav-link {
        font-size: 12.7px;
        padding: 0.5rem 0.5rem;
        font-weight: 900;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}

@media(max-width:767px){
    .nav-link {
        font-size: 15.5px;
        padding: 0.5rem 0.5rem;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}
`

const routeKeys = {
    conventional: "conventional",
    fha: "fha",
    investment: "investment",
    jumbo: "jumbo",
    renovation: "renovation",
    refinancing: "refinancing",
    reverse: "reverse",
    va: "va"
}

const LoanPrograms = ({ history }) => {
    const [key, setKey] = useState('va');
    const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;
    const [navItemClicked, setNavItemClicked] = useState(false);
    let { eventKey } = useParams();
    const mainContentRef = useRef(null);

    useEffect(() => {
        if (mainContentRef.current) {
            window.scrollTo({
                top: mainContentRef.current.offsetTop,
                behavior: "smooth"
            });
        }
        setNavItemClicked(false);
    }, []);

    const handleNavItemClick = (selectedKey) => {
        setKey(selectedKey);
        setNavItemClicked(true);
        if (mainContentRef.current) {
            window.scrollTo({
                top: mainContentRef.current.offsetTop,
                behavior: "smooth"
            });
        }
    };

    return (
        <article style={{ backgroundColor: '#FCFCFC' }}>

            <Helmet>

                <title>VA Loans | LowRates</title>
                <meta name="description" content="LowRates, Mortgage Loans" />
                <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />

            </Helmet>

            <div className={Classes.loanProgramDiv}>

                <img
                    src={imgCDNURL + "loanprogrambannerimage.webp"}
                    className={Classes.loanprogrambannerimage}

                />

                <img
                    src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
                    className={Classes.loanprogrambannerimagemobile}

                />

                <Container className={Classes.loanProgramContainer}>
                    <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
                        <img
                            src={imgCDNURL + "applynowbanner.webp"}
                            className={Classes.applynowbanner}
                        />
                    </a>
                    <NavWrapper>
                        <Tab.Container id="left-tabs-example" activeKey={key}
                            onSelect={handleNavItemClick}>
                            <Row>
                                <Col md={12} lg={12} sm={12} className={Classes.fixedLeft}>
                                    <h4 className={Classes.leftHeader}>Loan Services</h4>
                                    <Row>
                                        <Col md={6} lg={6} sm={12} >
                                            <Nav variant="pills" className={Classes.setNavLeft}>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.conventional} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/conventional-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.conventional);
                                                        }
                                                    }} to="/conventional-loans">CONVENTIONAL LOANS</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.fha} as={NavLink} to="/fha-loans" onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/fha-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.fha);
                                                        }
                                                    }} activeClassName={Classes.activePillLink} className={Classes.pillLink}>FHA LOANS</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.investment} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/investment-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.investment);
                                                        }
                                                    }} to="/investment-loans">INVESTMENT PROPERTIES</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.jumbo} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink1} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/jumbo-loan", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.jumbo);
                                                        }
                                                    }} to="/jumbo-loans">JUMBO LOANS</Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col md={6} lg={6} sm={12} >
                                            <Nav variant="pills" className={Classes.setNavLeft}>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.renovation} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/renovation-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.renovation);
                                                        }
                                                    }} to="/renovation-loans">RENOVATION AND CONSTRUCTION LOANS</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.refinancing} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/refinance", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.refinancing);
                                                        }
                                                    }} to="/refinance">REFINANCING</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.reverse} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/reverse-mortgage", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.reverse);
                                                        }
                                                    }} to="/reverse-mortgage">REVERSE MORTGAGE</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey={routeKeys.va} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink2} to="/va-loans">VA LOANS</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </NavWrapper>
                </Container>

                <div className={Classes.loanProgramContentDiv} ref={mainContentRef}>

                    <Container className={Classes.loanProgramContainer}>
                        <NavWrapper>
                            <Tab.Container id="left-tabs-example" activeKey={key}
                                onSelect={(k) => setKey(k)}>
                                <Row>

                                    <Col sm={12} lg={12} md={12} className={Classes.rightCol}>
                                        <h1 className={Classes.loanHeader}>
                                            {key === routeKeys.conventional && 'CONVENTIONAL LOANS'}
                                            {key === routeKeys.fha && 'FHA LOANS'}
                                            {key === routeKeys.investment && 'INVESTMENT PROPERTIES LOANS'}
                                            {key === routeKeys.jumbo && 'JUMBO LOANS'}
                                            {key === routeKeys.renovation && 'RENOVATION & CONSTRUCTION'}
                                            {key === routeKeys.refinancing && 'REFINANCING'}
                                            {key === routeKeys.reverse && 'REVERSE MORTGAGE / HECM LOANS'}
                                            {key === routeKeys.va && 'VA LOANS'}
                                        </h1>

                                        <div className={Classes.conventional}>
                                            <Tab.Pane eventKey={routeKeys.va}>
                                                <img
                                                    src={imgCDNURL + "valoanimage.webp"}
                                                    className={Classes.loanpurposeimage}
                                                    alt="va loan image"
                                                />
                                                <h3>What are VA Loans?</h3>
                                                <p>Guaranteed by the U.S. Department of Veteran Affairs, the VA loan program is designed to assist both current active duty military members and veterans achieve homeownership. Eligible borrowers for this program are allowed to borrow at a higher percentage of the home value and the loan is guaranteed by the government against default.</p>
                                                <h3>What are the Advantages of VA Loans?</h3>
                                                <p>There is no down payment required and no monthly mortgage insurance payments. The minimum credit score required is as low as 500.</p>
                                                <h3>Eligibility</h3>
                                                <p>Active members of the military, veterans, and members of the National Guard are qualified to apply for VA loans. Spouses of service-connected disability or deceased military members may also apply for these types of loans.</p>

                                                <h3>Why should you choose Real Mortgage Experts?</h3>
                                                <p>Our team offers a 24-hour loan center for future home owners  who don't have time to apply for a loan during regular business hours. We offer quick loan approvals and provide our clients with the best loan options for their needs and financial situations. Our team members are well-versed in VA loan rules and requirements and will ensure you are knowledgeable about our <Link to='/mortgage-process'>mortgage process</Link> prior to committing to a loan.</p>
                                                <p>For any questions about the VA loan process or any of our other home loan services, give our team at Real Mortgage Experts a call to speak to a local team member who can answer all of your home loan questions. <Link to='/contact'>Contact Us Today!</Link></p>

                                            </Tab.Pane>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </NavWrapper>

                    </Container>
                </div>
            </div>

            <div className={Classes.loanhowitworksDiv}>

                <Container className={Classes.loanhowitworksContainer}>
                    <HowItWorks />
                </Container>
            </div>

            <div className={Classes.loanresourcesDiv}>

                <Container className={Classes.loanresourcesContainer}>
                    <ResourcesMenu />
                </Container>
            </div>
        </article>
    )
}

export default LoanPrograms