// import { Container, Col, Row } from 'react-bootstrap';
import './index.css';

export const FactsSection = ({ styles }) => (
    <div>
        {/* <Col className={styles.facts_section_col}>
            <Row className={
                `${styles.facts_section_row} ${styles.light_gray}`
            }>
                <Col lg={1} sm={3} xs={3} className={styles.facts_section_row_heading}>
                    <span className={styles.strong}>Facts</span>
                </Col>
                <Col className={styles.facts_section_row_desc}>
                    <span>
                        What does Lowrates.com by Sun West Mortgage Company, Inc (NMLS ID 3277) do with your personal information?
                    </span>
                </Col>
            </Row>
            <Row className={`${styles.facts_section_row} ${styles.gray_row}`}>
                <Col lg={1} sm={3} xs={3} className={styles.facts_section_row_heading}>
                    <span>Why?</span>
                </Col>
                <Col className={styles.facts_section_row_desc}>
                    <span>
                        Financial companies choose how they share your personal information. Federal law gives&nbsp;
                        consumers the right to limit some but not all sharing. Federal law also requires us to&nbsp;
                        tell you how we collect, share, and protect your personal information. Please read this&nbsp;
                        notice carefully to understand what we do.
                    </span>
                </Col>
            </Row>
            <Row className={`${styles.facts_section_row} ${styles.light_gray}`}>
                <Col lg={1} sm={3} xs={3} className={styles.facts_section_row_heading}>
                    <span>What?</span>
                </Col>
                <Col className={styles.facts_section_row_desc}>
                    <span>
                        <span>The types of personal information we collect and share depend on&nbsp;
                            the product or service you have with us. This information can include:
                        </span>
                        <ul _ngcontent-ytb-c124="">
                            <li _ngcontent-ytb-c124="">Social Security number and income</li>
                            <li _ngcontent-ytb-c124="">Account balances and payment history</li>
                            <li _ngcontent-ytb-c124="">Credit scores and employment information</li>
                        </ul>
                    </span>
                </Col>
            </Row>
            <Row className={
                `${styles.facts_section_row} ${styles.gray_row} ${styles.border_bottom_none}`
            }>
                <Col lg={1} sm={3} xs={3} className={styles.facts_section_row_heading}>
                    <span>How?</span>
                </Col>
                <Col className={styles.facts_section_row_desc}>
                    <span>
                        All financial companies need to share customers’ personal information to run&nbsp;
                        their everyday business. In the section below, we list the reasons financial companies&nbsp;
                        can share their customers' personal information; the reasons LowRates.com by Sun West Mortgage Company,&nbsp;
                        Inc. chooses to share; and whether you can limit this sharing.
                    </span>
                </Col>
            </Row>
        </Col> */}

        <table width="100%" class ="table table-striped table-bordered">
            <tbody>
                <tr>
                    <td align="left">
                        <h4>FACTS</h4>
                    </td>
                    <td align="left">WHAT DOES LOWRATES.COM BY SUN WEST MORTGAGE COMPANY, INC. (NMLS ID 3277) DO WITH YOUR PERSONAL INFORMATION?</td>
                </tr>
                <tr>
                    <td align="left">
                        <h4>Why?</h4>
                    </td>
                    <td align="left">Financial companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all sharing. Federal law also requires us to tell you how we collect, share, and protect your personal information. Please read this notice carefully to understand what we do.</td>
                </tr>
                <tr>
                    <td align="left">
                        <h4>What?</h4>
                    </td>
                    <td align="left">
                        <p>The types of personal information we collect and share depend on the product or service you have with us. This information can include:</p>
                        <ul>
                            <li>Social Security number and income</li>
                            <li>Account balances and payment history</li>
                            <li>Credit scores and employment information</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td align="left">
                        <h4>How?</h4>
                    </td>
                    <td align="left">All financial companies need to share customers’ personal information to run their everyday business. In the section below, we list the reasons financial companies can share their customers’ personal information; the reasons LowRates.com by Sun West Mortgage Company, Inc. chooses to share; and whether you can limit this sharing.</td>
                </tr>
            </tbody>
        </table>
    </div>
)