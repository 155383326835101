import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import rootReducer from "./store/reducers";
import { legacy_createStore as createStore } from 'redux';
import { Provider } from "react-redux";
import Navbar from './components/Navbar';
import Main from './containers/App/index';
import LowRatesFooter from './components/LowratesFooter';
import axios from 'axios'; // Import axios

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

function App() {
  useEffect(() => {
    let redirected = false;

    const fetchData = async () => {
      try {
        const responseIpify = await axios.get("https://api.ipify.org", { timeout: 5000 });
        const userIP = responseIpify.data;
        
        if (userIP) {
          const responseMaxmind = await axios.post(
            process.env.REACT_APP_MAXMIND,
            { ip: userIP },
            { headers: { "Content-Type": "application/json" }, timeout: 5000 }
          );
          
          const maxmindData = responseMaxmind.data;
          const redirectionUrl = process.env.REACT_APP_REDIRECTION_URL;
          if (maxmindData !== "IP Not Available" && maxmindData !== "Invalid Country") {
            const userZipcode = maxmindData.zipcode;
            
            if (userZipcode) {
              const responseZipcode = await axios.get(
                process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE + userZipcode,
                { headers: { "Content-Type": "application/json" }, timeout: 5000 }
              );
              
              const userState = responseZipcode.data.state;
              const basename = process.env.REACT_APP_BASENAME;
              if (basename === "/" && userState === 'NY') {
                window.location.href = redirectionUrl;
                redirected = true;
              } else {
                
              }
            } else {
              locationError(); 
            }
          } else {
            locationError(); 
          }
        } else {
          locationError(); // Call locationError if there is an error with userIP
        }
      } catch (error) {
        console.error("Error: ", error);
        locationError(error); // Call locationError if there is any other error
      }
    };

    fetchData();
  }, []);

  const basename = process.env.REACT_APP_BASENAME;

  return (
    <Router basename={basename}>
      <Navbar />
      <Main />
      <LowRatesFooter />
    </Router>
  );
}

export default App;

// Define the locationError function somewhere in your code
function locationError(error) {
  // You can handle the error here, for example:
  console.error("Location Error: ", error);
  // You can also show a message to the user or perform any other action
}
