
import React, { useEffect, memo } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Container from 'react-bootstrap/Container';
import Classes from './index.module.css';
import FAQ from '../../components/FaqComponent';
import Loader from '../../components/LoadingIndicator';
import faqJson from './faq.json';



const key = 'FAQ';


function FAQPage({
    faq,
}) {
    const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    let faqBody;
    let faqArray = [];

    if (faqJson != "" && faqJson != undefined) {

        faqBody = faqJson.map((ele, index) => {
            let faqData = ele.qa.map(iEle => {
                return ({ 'header': iEle.question, 'data': iEle.answer });
            })
            return (
                <div className={Classes.openingPositions} id={ele.title}>
                    <h2 style={{
                        fontWeight: '600', marginBottom: '1%', position: 'relative', top: '3px'
                    }}>{ele.title}</h2>
                    <div></div>
                    <FAQ data={faqData} />
                </div>
            )
        })
    }




    return (
        <article>
            <Helmet>

                <title>FAQ | LowRates</title>
                <meta name="description" content="LowRates, Mortgage Loans" />
                <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />


            </Helmet>
            <div >
                <img
                    src={imgCDNURL + "loanprogrambannerimage.webp"}
                    className={Classes.loanprogrambannerimage}

                />
                <img
                    src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
                    className={Classes.loanprogrambannerimagemobile}

                />
                <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
                    <img
                        src={imgCDNURL + "applynowbanner.webp"}
                        className={Classes.applynowbanner}
                    />
                </a>
                <img
                    src={imgCDNURL + "faqimageontop.webp"}
                    className={Classes.faqimageontop}

                />
                <div className={Classes.title}>
                    <h1>Frequently Asked Questions</h1>
                </div>
                <div className={Classes.faqStyleDiv}>
                    <Container fluid className={Classes.pageContainer}>
                        <Container fluid >
                            {faq == "" ? <Loader /> : faqBody}
                        </Container>
                    </Container >
                </div>
            </div >
        </article >
    );
}


const mapStateToProps = createStructuredSelector({

    // faq: makeSelectFaq()

});

export function mapDispatchToProps(dispatch) {
    return {
        // fetchFaqData: () => { dispatch(get_faq_data()) }
    };
}

const withConnect = connect(
    null,
    null,
);

export default compose(
    withConnect,
    memo,
)(FAQPage);
