import { Container, Col, Row } from 'react-bootstrap';

export const ShareSection = ({ styles }) => (
    <Container className={styles.share_section} style={{
        padding: '0'
    }}>
        <Col className={styles.share_section_col}>
            <Row className={`${styles.share_section_row} ${styles.blue_row} ${styles.share_section_strong}`}>
                <Col lg={6} md={6} sm={6} xs={6} className={styles.share_section_row_desc}>
                    <span>
                        Does LowRates.com by Sun West Mortgage Company, Inc share?
                    </span>
                </Col>

                <Col lg={3} md={3} sm={3} xs={3} className={styles.share_section_row_flag2}>
                    <span>
                    Does LOWRATES.COM BY Sun West Mortgage Company, Inc. (NMLS ID 3277) Share?
                    </span>
                </Col>
                {/* <Col className={styles.share_section_row_flag1}>
                    <div style={{
                        visibility: 'hidden'
                    }}>
                        Limit sharing?
                    </div>
                </Col> */}
                <Col lg={3} md={3} sm={3} xs={3} className={styles.share_section_row_flag2}>
                    <span>
                        Limit sharing?
                    </span>
                </Col>
            </Row>
            <Row className={`${styles.share_section_row} ${styles.light_gray}`}>
                <Col lg={6} md={6} sm={6} xs={6} className={styles.share_section_row_desc}>
                    <span>
                        <span className={styles.share_section_strong}>For our everyday business purposes:</span>&nbsp;
                        such as to process your transactions, maintain your account(s), respond to court orders and&nbsp;
                        legal investigations, or report to credit bureaus.
                    </span>
                </Col>
                <Col className={`${styles.share_section_row_flag1} ${styles.share_section_strong}`}>
                    <span>
                        Yes
                    </span>
                </Col>
                <Col className={styles.share_section_row_flag2 + ' ' + styles.share_section_strong}>
                    <span>
                        No
                    </span>
                </Col>
            </Row>
            <Row className={`${styles.share_section_row} ${styles.gray_row}`}>
                <Col lg={6} md={6} sm={6} xs={6} className={styles.share_section_row_desc}>
                    <span>
                        <span className={styles.share_section_strong}>For our marketing purpose:</span>&nbsp;
                        to offer our products and services to you.
                    </span>
                </Col>
                <Col className={`${styles.share_section_row_flag1} ${styles.share_section_strong}`}>
                    <span>
                        Yes
                    </span>
                </Col>
                <Col className={`${styles.share_section_row_flag2} ${styles.share_section_strong}`}>
                    <span>
                        No
                    </span>
                </Col>
            </Row>
            <Row className={`${styles.share_section_row} ${styles.light_gray}`}>
                <Col lg={6} md={6} sm={6} xs={6} className={styles.share_section_row_desc}>
                    <span>
                        <span className={styles.share_section_strong}>For joint marketing with other financial companies</span>
                    </span>
                </Col>
                <Col className={`${styles.share_section_row_flag1} ${styles.share_section_strong}`}>
                    <span>
                        Yes
                    </span>
                </Col>
                <Col className={`${styles.share_section_row_flag2} ${styles.share_section_strong}`}>
                    <span>
                        No
                    </span>
                </Col>
            </Row>
            <Row className={`${styles.share_section_row} ${styles.gray_row}`}>
                <Col lg={6} md={6} sm={6} xs={6} className={styles.share_section_row_desc}>
                    <span>
                        <span className={styles.share_section_strong}>For our affiliates' everyday business purposes: </span>
                        information about your transactions and experiences.
                    </span>
                </Col>
                <Col className={`${styles.share_section_row_flag1} ${styles.share_section_strong}`}>
                    <span>
                        Yes
                    </span>
                </Col>
                <Col className={`${styles.share_section_row_flag2} ${styles.share_section_strong}`}>
                    <span>
                        No
                    </span>
                </Col>
            </Row>
            <Row className={`${styles.share_section_row} ${styles.light_gray}`}>
                <Col lg={6} md={6} sm={6} xs={6} className={styles.share_section_row_desc}>
                    <span>
                        <span className={styles.share_section_strong}>For our affiliates to market to you: </span>
                        information about your creditworthiness.
                    </span>
                </Col>
                <Col className={`${styles.share_section_row_flag1} ${styles.share_section_strong}`}>
                    <span>
                        Yes
                    </span>
                </Col>
                <Col className={`${styles.share_section_row_flag2} ${styles.share_section_strong}`}>
                    <span>
                        Yes
                    </span>
                </Col>
            </Row>
            <Row className={`${styles.share_section_row} ${styles.gray_row}`}>
                <Col lg={6} md={6} sm={6} xs={6} className={styles.share_section_row_desc}>
                    <span>
                        <span className={styles.share_section_strong}>For non-affiliates to market to you</span>
                    </span>
                </Col>
                <Col className={`${styles.share_section_row_flag1} ${styles.share_section_strong}`}>
                    <span>
                        Yes
                    </span>
                </Col>
                <Col className={`${styles.share_section_row_flag2} ${styles.share_section_strong}`}>
                    <span>
                        You must opt-in for us to share
                    </span>
                </Col>
            </Row>
        </Col>
    </Container>
)
