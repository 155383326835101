import React, { useState, useEffect, lazy, Suspense } from "react";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import logo43 from './assets/images/43-Years-Of-Trust.png'
import Classes from './footerNew.module.css';

const Footerchanges = lazy(() => import("../CommenFooterComponent/index"));

export default function Footer() {
  const getSunWestSuccessYears = () => {
    // Sun West founded in 08/12/1980 in PST
    const foundedDate = new Date("1980-08-12T00:00:00-07:00"); // PST offset is -07:00
    const todayDate = new Date();

    // Adjust todayDate to PST timezone
    const todayPST = new Date(
      todayDate.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
    );

    const yearDiff = todayPST.getFullYear() - foundedDate.getFullYear();
    const isDate2BeforeBirthday =
      todayPST.getMonth() < foundedDate.getMonth() ||
      (todayPST.getMonth() === foundedDate.getMonth() &&
        todayPST.getDate() < foundedDate.getDate());

    return isDate2BeforeBirthday ? yearDiff - 1 : yearDiff;
  };

  const baseName = process.env.REACT_APP_BASENAME;



  return (
    <>
      <footer className='py-md-2'>
        <Container fluid className={Classes.footernewchnages}>
          <Row className='pt-4 d-flex justify-content-center text-center'>

            <p ><strong>
              LowRates.com (NMLS ID 3277) by Sun West Mortgage Company, Inc.
              <br />
              &copy; {new Date().getFullYear()} LowRates.com All Rights Reserved
              <br />
              &copy; {new Date().getFullYear()} Sun West Mortgage Company, Inc.
              All Rights Reserved
            </strong></p>

            <p><strong>
              For licensing information, go to:{" "}
              <a
                href="http://www.nmlsconsumeraccess.org/"
                title="NMLS Consumer Access"
                target="_blank"
                rel="noreferrer"
                className="font-weight-bold"
                style={{ textDecoration: "underline" }}
              >
                www.nmlsconsumeraccess.org
              </a>
              . <br />
              Visit{" "}
              <a
                href="https://www.swmc.com/disclaimer"
                title="Click Here for full list of license information of Sun West Mortgage Company, Inc."
                target="_blank"
                rel="noreferrer"
                className="font-weight-bold"
                style={{ textDecoration: "underline" }}
              >
                www.swmc.com/disclaimer
              </a>{" "}
              for the full list of license information.
            </strong></p>
            <p>
              Please{" "}
              <a
                title="View Texas Complaint Notice and Servicing Disclosure"
                href="https://www.swmc.com/TXdis"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "underline" }}
              >
                Click Here
              </a>{" "}
              to view Texas Complaint Notice and Servicing Disclosure.
            </p>
            {process.env.REACT_APP_STATE_DOMAIN !==
              "ny.swmc.com" ? (
              <>
                <p>
                  <strong>
                    Although Sun West Mortgage is approved to conduct business in
                    the state of New York, this website has not yet been approved
                    by the State of New York Department of Financial Services.{" "}
                    <br />
                    For properties located in the State of New York, this website
                    cannot be used to upload an application, please visit{" "}
                    <a
                      href="https://www.swmc.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <u>SWMC.com</u>
                    </a>{" "}
                    to upload an application.
                  </strong>
                </p>
              </>
            ) : (
              ""
            )}
</Row>
        </Container>
      </footer>

            <Suspense fallback={<div />}>
              <div>
                <Footerchanges fetchpriority="low" />
              </div>
            </Suspense>

            <footer className='py-md-2'>
        <Container fluid className={Classes.footernewchnagesbottom}>
          <Row className='d-flex justify-content-center text-center'>
            <p>
              <a
                href="https://www.swmc.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{" "}
              -&nbsp;
              <a
                href="https://www.swmc.com/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>{" "}
              -&nbsp;
              <a href={`${baseName}disclaimers`} >
                Disclaimers
              </a>{" "}
              -&nbsp;
              <a href={`${baseName}sitemap`}>
                Site Map
              </a>

            </p>
          
</Row>
        </Container>
      </footer>
    </>
  );
}
