import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import styles from './index.module.css'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const TermsOfUse = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    return (
        <article>
            <Helmet>
                
                <title>Terms And Conditions | LowRates</title>
                <meta name="description" content="LowRates, Mortgage Loans"/>
                <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible"/>


         </Helmet>
        <Container className={styles.terms_of_use_container}>
            <div className={styles.title}>
                <h1>TERMS AND CONDITIONS</h1>
            </div>

            <p className={styles.para}>
                Please read these <span className={styles.strong_caps_text}>TERMS AND CONDITIONS OF USE </span>
                carefully. By using LowRates.com website or a related website belonging to Sun West Mortgage Company,&nbsp;
                Inc. (NMLS ID 3277) or one of its subsidiaries, you hereby agree to these&nbsp;
                <span className={styles.strong_caps_text}>TERMS AND CONDITIONS OF USE</span>. If you do not agree&nbsp;
                to these TERMS AND CONDITIONS OF USE, please do not use this site.
            </p>

            <p className={styles.para}>
                LowRates.com by Sun West Mortgage Company, Inc. (NMLS ID 3277) reserves the right at its&nbsp;
                discretion to change these <span className={styles.strong_caps_text}>TERMS AND CONDITIONS OF USE </span>
                at any time without notice. Your continued use of this website after the change of&nbsp;
                <span className={styles.strong_caps_text}>TERMS AND CONDITIONS OF USE</span> shall constitute your acceptance&nbsp;
                to agree to any such changes.
            </p>

            <p className={styles.para}>
                <span className={styles.strong_caps_text}>COPYRIGHT:</span> All information displayed,&nbsp;
                transmitted, or carried on the Site (including, but not limited to, text, photographs, news&nbsp;
                articles, opinions, reviews, trademarks, service marks and the like, collectively the “Content”)&nbsp;
                is protected by international copyright and other intellectual property laws. The Content is owned&nbsp;
                by LowRates.com, its affiliates or third-party licensors. Any use of the Content, including but not&nbsp;
                limited to, modification, distribution, reproduction, republication, transmission, posting, reposting,&nbsp;
                transfer, uploading, duplication or selling without the prior written permission of LowRates.com is&nbsp;
                strictly prohibited. You may make a single copy of the Content displayed on the Site for your&nbsp;
                personal, informational, non-commercial offline use only, provided the Content is not modified in any&nbsp;
                way and all copyright and other notice on any copy are retained. The use of any such Content on any&nbsp;
                other website or network computer environment is prohibited.
            </p>

            <p className={styles.para}>
                <span className={styles.strong_caps_text}>DISCLAIMER: </span>
                LowRates.com disclaims all warranties, express or implied, including but not limited to, implied&nbsp;
                warranties of merchantability or fitness for a particular purpose. LowRates.com does not represent&nbsp;
                or warrant those materials on the Site are accurate, complete, reliable, current, or error-free.&nbsp;
                LowRates.com does not represent or warrant that the Site or its server(s) are free of viruses or&nbsp;
                other harmful components.
            </p>

            <p className={styles.para}>
                <span className={styles.strong_caps_text}>LIMITATION OF LIABILITY: </span>
                In no event shall LowRates.com or its affiliates, licensors, employees, agents, or contractors be&nbsp;
                liable to you for any damages or losses, including but not limited to, direct, indirect, special,&nbsp;
                consequential, incidental, or punitive damages resulting from or caused by the Site, its public&nbsp;
                posting web boards, its content or any errors or omissions in its content, even if advised of&nbsp;
                possibility of such damages.
            </p>

            <p className={styles.para}>
                <span className={styles.strong_caps_text}>HYPERLINKS: </span> As a service&nbsp;
                to users of the Site, we include links to other sites and LowRates.com has no control over and makes&nbsp;
                no representations of any kind regarding the content or appropriateness of content on such sites,&nbsp;
                and you hereby irrevocably waive any claim against LowRates.com with respect to such sites.&nbsp;
            </p>

            <p className={styles.para}>
                <span className={styles.strong_caps_text}>SUBMISSIONS: </span> You&nbsp;
                acknowledge that by submitting messages or materials, including but not limited to, posting or&nbsp;
                email, to LowRates.com, you automatically grant LowRates.com a perpetual, royalty-free, irrevocable,&nbsp;
                non-exclusive right, and license to use, reproduce, modify, adapt, publish, translate, create&nbsp;
                derivative works from and distribute such materials or incorporate such materials into any form.&nbsp;
            </p>

            <p className={styles.para}>
                <span className={styles.strong_caps_text}>TERMINATION: </span> LowRates.com may, in its sole&nbsp;
                discretion, terminate, cancel or suspend your right to access to all or part of the service&nbsp;
                at the Site for any reason, at any time without notice. Upon termination, you must destroy&nbsp;
                all materials obtained from the Site, and all copies thereof, whether made under this TERMS&nbsp;
                AND CONDITIONS OF USE or otherwise.
            </p>

            <p className={styles.para}>
                <span className={styles.strong_caps_text}>MISCELLANEOUS: &nbsp;</span> 1. LowRates.com reserves&nbsp;
                the right to change, remove or modify any and all content contained on the Site at any&nbsp;
                time without notice. 2. You agree to report any copyright violations of the TERMS AND&nbsp;
                CONDITIONS OF USE to LowRates.com as soon as you become aware of them. In the event you&nbsp;
                have a claim of copyright infringement with respect to material that is contained in the&nbsp;
                Site, please notify us. (<Link to="/contact">Contact us</Link>)
            </p>

            <p className={styles.para}>
                <span className={styles.strong_caps_text}>AUTHORITY: </span> The only persons authorized&nbsp;
                to enter into contracts on behalf of Sun West Mortgage Company, Inc. are the company’s&nbsp;
                President and Chief Executive Officer. You may call our corporate office at <a href="tel:+18004537884">800.453.7884</a>&nbsp;
                or e-mail <a href="mailto:contracts@swmc.com">contracts@swmc.com</a> to verify the execution of any contract.
            </p>

            <p className={styles.para}>
                <span className={styles.strong_caps_text}>RECORDING: </span> Phone calls between you and&nbsp;
                any representatives of Sun West Mortgage, Inc. shall be recorded and monitored for quality&nbsp;
                assurance and training purposes.
            </p>
        </Container>
        </article>
    );
}

export default TermsOfUse;