import { Container, Col, Row } from 'react-bootstrap';

export const ProcessRelated = ({ styles }) => (
    <Container className={styles.share_section} style={{
        padding: '0'
    }}>
        <Col className={styles.share_section_col}>
            <Row className={`${styles.share_section_row} ${styles.blue_row} ${styles.share_section_strong}`}>
                <Col className={styles.share_section_row_desc}>
                    <h2 className='text-white'>
                        What we do
                    </h2>
                </Col>
            </Row>
            <Row className={`${styles.share_section_row} ${styles.light_gray}`}>
                <Col lg={4} md={4} sm={4} xs={4} className={`${styles.share_section_row_desc} ${styles.share_section_strong}`}>
                    <div>
                        How does LowRates.com by Sun West Mortgage Company, Inc. (NMLS ID 3277) protect my personal information?
                    </div>
                </Col>
                <Col className={styles.share_section_row_flag2}>
                    <div>
                        To protect your personal information from unauthorized access and use, we use security measures that comply&nbsp;
                        with federal law. These measures include computer safeguards and secured files and buildings. LowRates.com&nbsp;
                        by Sun West Mortgage Company, Inc. (NMLS ID 3277) employees are trained in our privacy policy and procedures.&nbsp;
                        Only employees who have a legitimate business reason to access your personal information may do so.
                    </div>
                </Col>
            </Row>
            <Row className={`${styles.share_section_row} ${styles.gray_row}`}>
                <Col lg={4} md={4} sm={4} xs={4} className={`${styles.share_section_row_desc} ${styles.share_section_strong}`}>
                    <div>
                        How does LowRates.com by Sun West Mortgage Company, Inc. (NMLS ID 3277) collect my personal information?
                    </div>
                </Col>
                <Col className={styles.share_section_row_flag2}>
                    <div>
                        <p>We collect your personal information, for example, when you:</p>
                            <ul>
                                <li>apply for a loan or give us your income information.</li>
                                <li>provide account information or show your driver’s license.</li>
                                <li>provide employment information.
                                </li>
                            </ul>
                            <p>
                                We also collect your personal information from others, such as credit bureaus, affiliates, {' '}
                                or other companies.
                            </p>
                    </div>
                </Col>
            </Row>
            <Row className={`${styles.share_section_row} ${styles.light_gray}`}>
                <Col lg={4} md={4} sm={4} xs={4} className={`${styles.share_section_row_desc} ${styles.share_section_strong}`}>
                    <div>
                        Why can’t I limit all sharing?
                    </div>
                </Col>
                <Col className={styles.share_section_row_flag2}>
                    <div>
                        <p>
                            Federal law gives you the right to limit only:
                        </p>
                        <ul>
                            <li>sharing for affiliates’ everyday business purposes – information about your creditworthiness.</li>
                            <li>affiliates from using your information to market to you.</li>
                            <li>sharing for non-affiliates to market to you.</li>
                        </ul>
                        <p>
                            State laws and individual companies may give you additional rights to limit sharing.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className={`${styles.share_section_row} ${styles.gray_row}`}>
                <Col lg={4} md={4} sm={4} xs={4} className={`${styles.share_section_row_desc} ${styles.share_section_strong}`}>
                    <div>
                        What happens when I limit sharing for an account I hold jointly with someone else?
                    </div>
                </Col>
                <Col className={styles.share_section_row_flag2}>
                    <div>
                        Your choices will apply to everyone on your account.
                    </div>
                </Col>
            </Row>
            <Row className={`${styles.share_section_row} ${styles.light_gray}`}>
                <Col lg={4} md={4} sm={4} xs={4} className={`${styles.share_section_row_desc} ${styles.share_section_strong}`}>
                    <div>
                        Does Sun West share my personal information with affiliates, if I/We do not Opt-Out?
                    </div>
                </Col>
                <Col className={styles.share_section_row_flag2}>
                    <div>
                        Sun West can begin sharing all borrowers' information with the affiliates, 30 days from the date we sent this notice.&nbsp;
                        However, you can contact us at any time to limit our sharing.
                    </div>
                </Col>
            </Row>
        </Col>
    </Container>
)