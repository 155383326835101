import React, { useState, useEffect, useRef } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import Classes from './index.module.css';
import { capitalizeFirstLetter, isMobileOrTablet } from "../Utils";
import styled from 'styled-components';
import { IoHomeSharp } from 'react-icons/io5';
import UserWayAccessibilityMenu from "../UserWayAccessibilityMenu/index.js";

const NavWrapper = styled.div`
.navbar-dark .navbar-nav .nav-link{
    color: #4f4f4f;
    z-index: 1200;
    font-weight: 600;
    font-size: 15px;
    margin-right: 40px;

    @media (min-width: 991px) and (max-width: 1200px) {
        margin-right: 20px !important;
      }
}
.navbar {
    background: #ffffff !important;
    transition: all .3s ease-in;
}
.navbar.active {
    background: #ffffff !important;
  }
  @media screen and (max-width: 991px) {
    .navbarTransition {
        transform: translateY(-100%);
        transition: all 0.3s ease-in;
      }
  }
.navbar-dark .navbar-nav .nav-link:hover{
    color: #0054a6;
    // border-bottom: 4px solid #0054a6;

    @media (min-width: 320px) and (max-width:992px) {
        border-bottom: none !important;
      }
}
.navbar-dark .navbar-nav .nav-link.active{
    color:#0054a6;
    border-bottom: 4px solid #0054a6;

    @media (min-width: 320px) and (max-width:992px) {
        border-bottom: none !important;
      }
}
.navbar-expand-lg .navbar-nav .dropdown-menu{
    margin: 0px 0px 0px -30px !important;

    @media (min-width: 320px) and (max-width:992px) {
        margin: 0px 0px 0px 0px !important;
      }

      @media (min-width: 992px) and (max-width: 1024px) {
        margin: 0px 0px 0px -40px !important;
      }
}

.Navbar_ratesButton__2hEOQ.nav-link {
    color: #fff !important;
}

._3yQ6ipwnXEJX5uUyBeOBFR.btn.btn-primary.btn-md {
    font-weight: 600;
}
}

.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
    border-color:white;
}
.btn-primary:focus {
    border-color: white;
}
.btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background: #fff;
    color: #0481b5;
    border:1px solid #fff;
    box-shadow:none;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
    background: #fff;
    color: #0481b5;
    border:1px solid #fff;
    box-shadow:none;
}

.channelsNav{
    .dropdown-submenu-container a.dropdown-submenu::after {
        position: absolute;
        right: 0;
        top: 0;
        
    }
.dropdown-menu{
    .dropdown-toggle::after {
        content: ">";
        vertical-align: right;
        border: none;
        margin-top: 4px;
        font-weight: 400;
        color: #dddd;
        transform: rotate(0) !important;
    }
    padding-right:15px !important;
}
}

@media(min-width:992px) and (max-width:1200px){
    .nav-link {
        font-size: 14px !important;
    }

}
@media(max-width:992px){
    .navbar-toggler {
        padding: .25rem .75rem;
        font-size: 1.25rem;
        line-height: 1;
        color: #fff;
        background-color: #0054a6;;
        border: 1px solid transparent;
        border-radius: .25rem;
    }
    .nav-link {
        font-size: 14px !important;
    }
    #responsive-navbar-nav {
    // background-color: #c7cbd0;
    background-color: white;
    padding: 20px 10px;
    }
}
.dropdown-menu {
    background: #1f2127 !important;
    color: #fff !important;
    width: 200px;
    border-radius: none !important;
    top: 100%;
    font-size: 13px;
    line-height: 26px;
    font-weight: 400;
    padding: 0px 0px;
    @media (min-width: 320px) and (max-width:991.7px) {
        background: #0054a6 !important;
    }
}

.dropdown-item:last-child {
    border-bottom: 0!important;
    border-radius: none !important;
}

.dropdown-item {
    font-size: 13px;
    padding: 7px 24px !important;
    color: #ffffff !important;
    font-weight: 400;
    line-height: 26px;
    border-bottom: 0.5px solid #cccccc !important;
    white-space: normal;
}
.dropright .dropdown-menu {
    padding-left: 0;
    top: 40%;
}
.dropdown-item:hover, .dropdown-item:focus {
    background-color: #0054a6 !important; 
    color: #fff !important;
}
@media only screen and (max-width: 1298.97px) {
    .dropdown-menu {
        width: 200px;
    
    @media (min-width: 320px) and (max-width:992px) {
        width: 100% !important;
        // background-color: #0054a6 !important;
      }
    }
}

`;

const Navigation = (props) => {
    // const [scrollPosition, setScrollPosition] = useState(0);
    // const [navbarStyle, setNavbarStyle] = useState('')
    const [logoImage, setLogoImage] = useState('')
    const [fortyYears, setFortyYears] = useState('')
    const [applyButton, setApplyButton] = useState('')
    const [hoveredLink, setHoveredLink] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [hoveredDropdown, setHoveredDropdown] = useState(null);
    const [showDropdown1, setShowDropdown1] = useState(false);
    const [showDropdown2, setShowDropdown2] = useState(false);
    const [showDropdown3, setShowDropdown3] = useState(false);
    const [isLoanProgramsHovered, setIsLoanProgramsHovered] = useState(false);
    const [showLoanProgramsItems, setShowLoanProgramsItems] = useState(false);
    const [isResourcesHovered, setIsResourcesHovered] = useState(false);
    const [showResourcesItems, setShowResourcesItems] = useState(false);
    const [isTalktousHovered, setIsTalktousHovered] = useState(false);
    const [showTalktousItems, setShowTalktousItems] = useState(false);
    const [isToggling, setIsToggling] = useState(false);
    const [navbar, setNavbar] = useState(false);
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollingUp, setScrollingUp] = useState(false);
    const isMobileOrTabletDevice = isMobileOrTablet();
    const location = useLocation();


    const menuRef = useRef(null);
    const imgCDN = process.env.REACT_APP_LOWRATES_RESOURCES 

    // Function to close the menu when clicked outside
    const handleOutsideClick = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setIsNavExpanded(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", () => {
            setIsNavExpanded(false);
        });

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", () => {
                setIsNavExpanded(false);
            });
        };
    }, []);

    // Add an event listener to close the menu when the user scrolls
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setIsNavExpanded(false);
        });

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", () => {
                setIsNavExpanded(false);
            });
        };
    }, []);



    let prevScrollPos = window.scrollY;

    window.onscroll = function () {
        const currentScrollPos = window.scrollY;
        const Navbar = document.getElementById("Navbar");

        if (currentScrollPos === 0) {
            Navbar.classList.remove("navbarTransition");
            setNavbar(false);
        } else {
            if (prevScrollPos > currentScrollPos) {
                setNavbar(true);
            } else if (prevScrollPos < currentScrollPos) {
                if (!isMobileOrTabletDevice) {
                    setNavbar(true);
                } else {
                    setNavbar(false);
                    Navbar.classList.add("navbarTransition");
                }
            }
        }

        prevScrollPos = currentScrollPos;
    };

    const handleMenuClick = () => {
        setNavbar(false);
    };



    function scrollToSection1() {
        const sectionPosition = document.getElementById("contact-wrapper").offsetTop;
        window.scrollTo({
            top: sectionPosition,
            behavior: "smooth",
        });
        setNavbar(false);
    }

    // Event handler for hovering over "Loan Programs"
    const handleLoanProgramsHover = () => {
        setIsLoanProgramsHovered(!isLoanProgramsHovered); // Toggle the hover state
        setShowDropdown1(!showDropdown1); // Toggle the visibility of the dropdown
        setShowLoanProgramsItems(true); // Show the dropdown items when hovering
    };

    // Event handler for leaving "Loan Programs"
    const handleLoanProgramsLeave = () => {
        setIsLoanProgramsHovered(false);
        if (!isLoanProgramsHovered) {
            setShowDropdown1(false); // Close the dropdown when leaving "Loan Programs"
            setShowLoanProgramsItems(false); // Hide the dropdown items when leaving
        }
    };

    // Event handler for clicking on "Loan Programs" title
    const handleLoanProgramsClick = () => {
        setShowLoanProgramsItems(!showLoanProgramsItems); // Toggle the visibility of the dropdown items
    };

    const handleResourcesHover = () => {
        setIsResourcesHovered(!isResourcesHovered);
        setShowDropdown2(!showDropdown2);
        setShowResourcesItems(true);
    };

    const handleResourcesLeave = () => {
        setIsResourcesHovered(false);
        if (!isResourcesHovered) {
            setShowDropdown2(false);
            setShowResourcesItems(false);
        }
    };

    const handleTalktousHover = () => {
        setIsTalktousHovered(!isTalktousHovered);
        setShowDropdown3(!showDropdown3);
        setShowTalktousItems(true);
    };

    const handleTalktousLeave = () => {
        setIsTalktousHovered(false);
        if (!isTalktousHovered) {
            setShowDropdown3(false);
            setShowTalktousItems(false);
        }
    };

    const handleResourcesClick = () => {
        setShowResourcesItems(!showResourcesItems);
    };

    const handleDropdownToggle1 = () => {
        setShowDropdown1(!showDropdown1);
    };

    const handleDropdownToggle2 = () => {
        setShowDropdown2(!showDropdown2);
    };

    const handleDropdownToggle3 = () => {
        setShowDropdown3(!showDropdown3);
    };

    const handleLinkHover = (linkNumber) => {
        setHoveredLink(linkNumber);
    };

    const handleLinkLeave = () => {
        setHoveredLink(null);
    };

    const toggleMenu = () => {
        setIsNavExpanded((prevExpanded) => !prevExpanded);
    };

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);

        // Check if Navbar is being toggled and close it first before handling scroll
        if (isToggling && position === 0) {
            setIsNavExpanded(false);
            setIsToggling(false); // Reset toggling state after closing the Navbar
        }
        setScrollPosition(position);
    };


    useEffect(() => {
        setLogoImage(imgCDN +"logo_03.png")
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isToggling]);

    useEffect(() => {
        let prevScrollPos = window.scrollY;

        window.onscroll = function () {
            const currentScrollPos = window.scrollY;

            if (isNavExpanded) {
                setIsNavExpanded(false);
                return;
            }

            if (currentScrollPos === 0) {
                setNavbar(false);
            } else {
                if (prevScrollPos > currentScrollPos) {
                    setNavbar(true);
                } else if (prevScrollPos < currentScrollPos) {
                    setNavbar(true);
                }
            }

            prevScrollPos = currentScrollPos;
        };

        return () => {
            window.removeEventListener('scroll', () => { });
        };
    }, [isNavExpanded]);

    const handleNavbarToggle = (isOpen) => {
        setIsNavExpanded(isOpen);
        setIsToggling(true);
    };



    return (

        <NavWrapper>

            <Navbar id="Navbar" collapseOnSelect expand="lg" fixed="top" variant="dark" className={navbar ? "navbar active" : "navbar"} expanded={isNavExpanded}
                onToggle={handleNavbarToggle}>
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggleMenu} />
                    <Navbar.Brand>
                        <NavLink to={{ pathname: '/', params: '' }}>
                            <img
                                src={logoImage}
                                className={Classes.main_logo} height="auto" alt="lowrates-logo" />
                        </NavLink>
                    </Navbar.Brand>
                    <UserWayAccessibilityMenu color="white" width={30} height={30} />

                    <Navbar.Collapse id="responsive-navbar-nav" in={isNavExpanded}>
                        <Nav className="ms-auto">
                            <Nav.Link href as={NavLink} to='/'><IoHomeSharp style={{ marginRight: '10px', fontSize: '12px', marginTop: '-4px' }} />Home</Nav.Link>
                            <Nav.Link href as={NavLink} to='/about-us'>About Us</Nav.Link>
                            <NavDropdown title="Loan Programs" id="loanPrograms" show={showDropdown1 || isLoanProgramsHovered || showLoanProgramsItems} // Show the dropdown if the state is true
                                onMouseEnter={handleDropdownToggle1}
                                onMouseLeave={handleDropdownToggle1}
                                onClick={handleDropdownToggle1}
                                onMouseLeave={handleLoanProgramsLeave}>
                                <NavDropdown.Item as={Link} to={`/conventional-loans`} onClick={() => setIsNavExpanded(false)}>Conventional Loans</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`/fha-loans`} onClick={() => setIsNavExpanded(false)}>FHA Loans</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`/investment-loans`} onClick={() => setIsNavExpanded(false)}>Investment Properties</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`/jumbo-loans`} onClick={() => setIsNavExpanded(false)}>Jumbo Loans</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`/renovation-loans`} onClick={() => setIsNavExpanded(false)}>Renovation And Construction Loans</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`/refinance`} onClick={() => setIsNavExpanded(false)}>Refinancing</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`/reverse-mortgage`} onClick={() => setIsNavExpanded(false)}>Reverse Mortgage</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`/va-loans`} onClick={() => setIsNavExpanded(false)}>VA Loans</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Resources" id="resources" show={showDropdown2 || isResourcesHovered || showResourcesItems}
                                onMouseEnter={handleDropdownToggle2}
                                onMouseLeave={handleDropdownToggle2}
                                onClick={handleDropdownToggle2}
                                onMouseLeave={handleResourcesLeave}>
                                <NavDropdown.Item as={Link} to={`/home-buying`} onClick={() => setIsNavExpanded(false)}>Home Buyer's Guide</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`/refinancing-guide`} onClick={() => setIsNavExpanded(false)}>Refinancing Guide</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`/documents`} onClick={() => setIsNavExpanded(false)}>Documents Needed</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={`https://www.swmc.com/angelai/calculators`} target='_blank' onClick={() => setIsNavExpanded(false)}>Mortgage Calculators</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Talk To Us" id="talkToUs" show={showDropdown3 || isTalktousHovered || showTalktousItems}
                                onMouseEnter={handleDropdownToggle3}
                                onMouseLeave={handleDropdownToggle3}
                                onClick={handleDropdownToggle3}
                                onMouseLeave={handleTalktousLeave}>
                                <NavDropdown.Item as={Link} to="/faq" onClick={() => setIsNavExpanded(false)}>FAQ</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/contact" onClick={() => setIsNavExpanded(false)}>Contact Us</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="https://www.swmc.com/ApplyNow/?c=lowrates" onClick={() => setIsNavExpanded(false)}>Apply Now</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                    {/* </div> */}
                </Container>
            </Navbar>

        </NavWrapper >

    )
}

export default Navigation;

