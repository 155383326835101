import React, { useEffect, useRef, useState } from 'react';
import Classes from '../../containers/HomeBuyers/index.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { NavLink, Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BlueButton from '../../components/BlueButton';
import ResourcesMenu from '../../components/ResourcesMenu';
import HowItWorks from '../../components/HowItWorks';
import WhiteButton from '../../components/WhiteButton';
import RequiredDocs from '../RequiredDocs';
import Calculators from '../Calculators';
import { Helmet } from 'react-helmet';


const NavWrapper = styled.div`
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    color: #00AAE4;
    background-color: transparent;
    // border-bottom:1px solid #3074b7;
    padding: 0.8rem 1rem;
    border-radius: 0!important;

}
.nav-link {
    display: block;
    font-size: 20.1px;
    font-weight: 800;
    padding: 0.8rem 1rem;
    color: #083F88;
    border-radius: 0!important;
}

@media(min-width: 1200px)and (max-width:1400px) {
    .nav-link {
        font-size: 18.1px;
    }
}

@media(min-width: 992px)and (max-width:1199.7px) {
    .nav-link {
        font-size: 15.1px;
        font-weight: 900;
    }
}

@media(min-width: 768px)and (max-width:991.7px) {
    .nav-link {
        font-size: 12.7px;
        padding: 0.5rem 0.5rem;
        font-weight: 900;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}

@media(max-width:767px){
    .nav-link {
        font-size: 15.5px;
        padding: 0.5rem 0.5rem;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}
}
`

const routeKeys = {
    homebuyersguide: "homebuyersguide",
    refinancingguide: "refinancingguide",
    documentsneeded: "documentsneeded",
    mortgagecalculator: "mortgagecalculator"
}

const Resources = ({ history }) => {
    const [key, setKey] = useState('refinancingguide');
    const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;
    const [navItemClicked, setNavItemClicked] = useState(false);
    let { eventKey } = useParams();
    const mainContentRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleNavItemClick = (selectedKey) => {
        setKey(selectedKey);
        setNavItemClicked(true);
        if (mainContentRef.current) {
            window.scrollTo({
                top: mainContentRef.current.offsetTop,
                behavior: "smooth"
            });
        }
    };

    return (
        <article style={{ backgroundColor: '#FCFCFC' }}>

            <Helmet>
                <title>Refinancing Guide | LowRates</title>
                <meta name="description" content="LowRates, Mortgage Loans" />
                <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />

            </Helmet>

            <div className={Classes.loanProgramDiv}>

                <img
                    src={imgCDNURL + "loanprogrambannerimage.webp"}
                    className={Classes.loanprogrambannerimage}

                />

                <img
                    src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
                    className={Classes.loanprogrambannerimagemobile}

                />

                <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
                    <img
                        src={imgCDNURL + "applynowbanner.webp"}
                        className={Classes.applynowbanner}
                    />
                </a>

                {key !== routeKeys.documentsneeded && key !== routeKeys.mortgagecalculator && (
                    <Container className={Classes.loanProgramContainer}>

                        <NavWrapper>
                            <Tab.Container id="left-tabs-example" activeKey={key}
                                onSelect={handleNavItemClick}>
                                <Row>
                                    <Col md={12} lg={12} sm={12} className={Classes.fixedLeft}>
                                        <h4 className={Classes.leftHeader}>Loan Services</h4>
                                        <Row>
                                            <Col md={6} lg={6} sm={12} >
                                                <Nav variant="pills" className={Classes.setNavLeft}>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.conventional} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/conventional-loans", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.conventional);
                                                            }
                                                        }}
                                                            to="/conventional-loans">CONVENTIONAL LOANS</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.fha} as={NavLink} to="/fha-loans" activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/fha-loans", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.fha);
                                                            }
                                                        }}>FHA LOANS</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.investment} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/investment-loans", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.investment);
                                                            }
                                                        }} to="/investment-loans">INVESTMENT PROPERTIES</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.jumbo} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink1} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/jumbo-loan", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.jumbo);
                                                            }
                                                        }} to="/jumbo-loans">JUMBO LOANS</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                            <Col md={6} lg={6} sm={12} >
                                                <Nav variant="pills" className={Classes.setNavLeft}>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.renovation} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/renovation-loans", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.renovation);
                                                            }
                                                        }} to="/renovation-loans">RENOVATION AND CONSTRUCTION LOANS</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.refinancing} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/refinance", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.refinancing);
                                                            }
                                                        }} to="/refinance">REFINANCING</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.reverse} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/reverse-mortgage", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.reverse);
                                                            }
                                                        }} to="/reverse-mortgage">REVERSE MORTGAGE</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={routeKeys.va} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink2} onClick={(e) => {
                                                            if (e.ctrlKey) {
                                                                window.open("/va-loans", "_blank");
                                                            } else {
                                                                handleNavItemClick(routeKeys.va);
                                                            }
                                                        }} to="/va-loans">VA LOANS</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </NavWrapper>
                    </Container>
                )}

                <div ref={mainContentRef} className={key !== routeKeys.documentsneeded && key !== routeKeys.mortgagecalculator ? Classes.loanProgramContentDiv : Classes.documentsNeededStyleDiv}>
                    <Container className={Classes.loanProgramContainer}>
                        <NavWrapper>
                            <Tab.Container id="left-tabs-example" activeKey={key}
                                onSelect={(k) => setKey(k)}>
                                <Row>

                                    <Col sm={12} lg={12} md={12} className={Classes.rightCol}>
                                        <h1 className={Classes.loanHeader}>
                                            {key === routeKeys.homebuyersguide && 'HOME BUYER\'S GUIDE'}
                                            {key === routeKeys.refinancingguide && 'REFINANCING GUIDE'}
                                        </h1>

                                        <div className={Classes.conventional}>
                                            <Tab.Pane eventKey={routeKeys.refinancingguide}>
                                                <img
                                                    src={imgCDNURL + "refiguideimage.webp"}
                                                    className={Classes.loanpurposeimage}
                                                    alt="refinancing guide image"
                                                />
                                                <h3>Prepare for Refinancing</h3>
                                                <p>Before talking to your lender and moving forward with your application, decide if refinancing is the right move for you. Three reasons why home owners choose to refinance their mortgage are:</p>
                                                <ul>
                                                    <li>To get a lower monthly payment</li>
                                                    <li>To take out cash</li>
                                                    <li>Or to shorten the lifespan of the mortgage</li>
                                                </ul>
                                                <p>If one of these reasons sounds like something you and your family are interested in, talk to your LowRates specialist to see if you qualify. We will consider your credit score, your monthly mortgage payment, the overall value of your home, and your family’s debt-to-income ratio before deciding if you qualify, so it’s a good idea to have these numbers prepared and know if you’re a likely candidate before applying.</p>
                                                <h3>Analyze Your Options</h3>
                                                <p>Once you know the reasons people refinance a mortgage, it's important to identify why you might want to refinance.</p>
                                                <p>Are you looking to get a lower monthly payment on your current mortgage? If yes, you may benefit from refinancing. This option can allow you to lower the interest rate on your monthly payment or change your current mortgage term. If you find that the interest rates on your current mortgage are causing you financial stress, or you would like to spread out your payments so you pay less over a longer period of time, refinancing can help you accomplish your goals.</p>
                                                <p>What if you're wanting to take cash out? Taking a loan out that increases your current mortgage term can help you pay off any outstanding debt, and will give you more freedom to make your mortgage payments over a longer amount of time.</p>
                                                <p>However, if you're realizing you don’t want to be committed to making mortgage payments for the next 20 to 30 years, you can refinance your current mortgage to shorten your term and pay less interest over a shorter amount of time. Sounds like a win-win, right?</p>
                                                <h3>Send in Your Application</h3>
                                                <p>Taking this information into account, is refinancing the right option for you? If so, it's time to refinance your mortgage with one our loan officers. Give us a call to schedule an appointment with one of our team members and begin your refinancing application. Bringing your financial information, your goals, and these questions to your new loan officer can help expedite the refinancing process:</p>
                                                <ul>
                                                    <li>Will you service my loan after it closes?</li>
                                                    <li>What are your rates?</li>
                                                    <li>Are you available to answer my questions throughout the week?</li>
                                                    <li>How have you helped clients in the past?</li>
                                                    <li>How long will it take me to get refinance completed?</li>
                                                    <li>Don't forget to bring important documents to the meeting with your loan officer, like your most recent pay stubs, your two most recent W-2 forms, as well as bank statement from the past two months.</li>
                                                </ul>
                                                <h3>Get an Appraisal</h3>
                                                <p>After sending in your application with the help of your loan officer, it's time to get an underwriting and an appraisal.</p>
                                                <p>For the underwriting process, our team will double check to make sure that all of the financial documents you provided match up with the requirements of the loan.</p>
                                                <p>This process can take between one to two weeks, not including the time it takes to receive the appraisal. An appraisal serves as the final check to make sure the value of your home is as stated by the home sellers. Taking nearby homes into account, the appraiser will make sure you are getting a fair price on the home.</p>
                                                <h3>Manage Your Mortgage</h3>
                                                <p>After you've officially closed on your new refinancing loan, the majority of the responsibility falls on you to make sure you're making payments on time. Your LowRates agent will always be available to answer any questions you have or help you with options should you have difficulty making monthly payments.</p>
                                            </Tab.Pane>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </NavWrapper>
                    </Container>
                </div>
            </div>

            {key !== routeKeys.documentsneeded && key !== routeKeys.mortgagecalculator && (
                <div className={Classes.loanhowitworksDiv}>

                    <Container className={Classes.loanhowitworksContainer}>
                        <HowItWorks />
                    </Container>
                </div>
            )}

            <div className={Classes.loanresourcesDiv}>

                <Container className={Classes.loanresourcesContainer}>
                    <ResourcesMenu />
                </Container>
            </div>
        </article>
    )
}

export default Resources